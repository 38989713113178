@use 'sass:math';

@import 'breakpoints.scss';
@import 'colors.scss';

$secondary: themeColor('dietbet', 'secondary');

%step {
  display: flex;
  justify-content: center;
  height: 190px;
  background: $secondary;
  color: $white;
  text-align: center;

  @include from(3) {
    flex: 0 0 375px;
  }

  @include to(2) {
    width: 100%;
  }
}

%triangle {
  $height: 30px;
  $width: 18px;

  content: '';
  position: absolute;
  top: calc(50% - #{math.div($height, 2)});
  width: 0;
  height: 0;
  border-width: 0 $height $width;
  border-style: solid;
  border-color: transparent transparent $secondary;
}

.container:nth-of-type(2n + 1) {
  @extend %step;

  margin-bottom: 10px;

  @include from(3) {
    margin-right: 15px;
  }

  .content::before {
    @extend %triangle;

    left: -38px;
    transform: rotate(0.75turn);
  }
}

.container:nth-of-type(2n) {
  @extend %step;

  flex-direction: row-reverse;
  margin-bottom: 25px;

  .content::before {
    @extend %triangle;

    right: -38px;
    transform: rotate(0.25turn);
  }
}

.image {
  flex: 0 0 150px;
  height: 190px;
}

.content {
  display: flex;
  position: relative;
  flex: 0 0 225px;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 0;
}

.description {
  font-size: 17px;
}
