@import 'breakpoints.scss';

.container {
  @include from(3) {
    padding: 140px 120px;
  }

  @include to(2) {
    padding: 140px 20px;
  }
}
