@import 'breakpoints.scss';

.container.container {
  display: none;

  @include from(2) {
    padding: 96px 0;
  }

  @include to(1) {
    padding: 20px;
  }
}

.widgetForm {
  height: 40px;
  margin: 0 auto;
  font-size: 21px;

  @include from(2) {
    width: 500px;
  }
}

.title {
  margin-bottom: 32px;
  text-align: center;

  @include from(2) {
    font-size: 32px;
  }

  @include to(1) {
    font-size: 22px;
  }
}

.all.all {
  display: block;
}

.small.small {
  @include to(2) {
    display: block;
  }
}

.medium.medium {
  @include between(2, 3) {
    display: block;
  }
}

.large.large {
  @include from(3) {
    display: block;
  }
}
