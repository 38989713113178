@import 'utils.scss';
@import 'breakpoints.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include to(1) {
    padding: 120px 20px 184px;
  }

  @include from(2) {
    padding: 120px 0 184px;
  }
}

.title {
  margin-bottom: 50px;
  font-size: 38px;
  font-weight: 400;

  @include to(1) {
    text-align: center;
  }
}

.statement {
  @include markdownRemoveMargins;

  max-width: 500px;
  margin-bottom: 70px;
  font-size: 21px;
  line-height: 30px;
  text-align: center;
  white-space: pre-line;

  @include to(1) {
    white-space: normal;
  }

  @include from(2) {
    white-space: pre-line;
  }
}

.steps {
  > * {
    // NOTE: Fixes IE11 bug.
    // See https://github.com/philipwalton/flexbugs#flexbug-2

    max-width: 100%;
    margin-top: 76px;
    margin-right: 40px;
    margin-left: 40px;
  }
}
