@import 'breakpoints.scss';
@import 'utils.scss';

.container {
  display: flex;

  @include from(3) {
    justify-content: space-around;
  }

  @include to(2) {
    justify-content: center;
    padding: 40px 50px;
  }
}

.content {
  text-align: center;

  @include from(3) {
    padding: 80px 0 67px;
  }
}

.icon {
  height: 60px;
  margin-bottom: 30px;

  @include from(3) {
    display: none;
  }
}

.title {
  @include from(3) {
    margin-bottom: 38px;
    font-size: 35px;
  }

  @include to(2) {
    margin-bottom: 30px;
    font-size: 24px;
  }
}

.divider {
  height: 20px;
  margin-bottom: 10px;
}

.blurb {
  @include markdownRemoveMargins;

  margin-bottom: 40px;
  font-size: 19px;
  line-height: 23px;

  @include from(3) {
    margin-right: auto;
    margin-left: auto;
  }
}

.badges {
  @include from(3) {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
}

.badge {
  display: block;
  height: 53.35px; // equals ~180px wide
  margin: 0 10px 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.widget.widget {
  max-width: 355px;
  margin: 0 auto;

  @include to(2) {
    display: none;
  }
}

.widgetTitle {
  margin-bottom: 14px;
  font-size: 15px;
}

.widgetForm {
  height: 48px;
  font-size: 17px;
}

@each $theme, $themeColors in $themes {
  $secondary: map-get($themeColors, 'secondary');

  .#{$theme} .widgetButton {
    background: darken($secondary, 25%);
  }
}

.imageContainer {
  display: flex;
  align-items: flex-end;
  padding-top: 60px;
}

.image {
  height: 477px;

  @include to(2) {
    display: none;
  }
}
