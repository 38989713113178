@import 'breakpoints.scss';
@import 'utils.scss';
@import 'colors.scss';

.container {
  position: relative;
  align-items: center;
  height: 350px;

  @include to(1) {
    padding: 0 20px;
  }
}

.links {
  @include plainLinks;

  position: absolute;
  right: 0;
  bottom: 32px;
  left: 0;
  width: 200px;
  margin: auto;
  font-size: 21px;
  font-weight: 600;
  text-align: center;

  a {
    display: inline-block;
    width: 50%;
    border-left: 1px solid $white;
    line-height: 32px;

    &:first-child {
      border: 0;
    }
  }
}
