@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex: 0 0 220px;
  flex-direction: column;
  align-items: center;
  margin: 0 16px;
  text-align: center;

  > * {
    // NOTE: Fixes IE11 bug.
    // See https://github.com/philipwalton/flexbugs#flexbug-2

    max-width: 100%;
  }
}

.image {
  flex: 0 0 auto;
  margin-bottom: 30px;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.description {
  white-space: normal;
}

@each $theme, $themeColors in $themes {
  .#{$theme} .title {
    color: map-get($themeColors, 'secondary');
  }
}
