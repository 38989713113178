@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  width: 100%;
  padding: 40px 0 20px;
}

.inner.inner {
  padding: 25px 0;
}

.camera {
  display: block;
  margin: 0 auto 25px;
}

.subtitle {
  margin: 0 0 25px;
  color: $grey700;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.steps {
  @include from(3) {
    display: flex;
    justify-content: center;
  }
}

.listContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.list {
  display: grid;
  // grid-column-gap: 40px;
  // grid-row-gap: 10px;
  gap: 10px 40px;
  grid-template-columns: auto 122.5px;
  grid-template-rows: repeat(2, max-content);
  margin: 0 auto;
  color: $grey700;
  font-size: 13px;
  font-weight: normal;

  strong {
    display: block;
    font-weight: 600;
  }

  li > p {
    margin: 0;
  }

  li:nth-child(3) {
    grid-column-start: 2;
    // grid-row-end: span 2;
    // grid-row-start: 1;
    grid-row: 1 / span 2;
  }
}
