@import 'utils.scss';
@import 'colors.scss';
@import 'breakpoints.scss';

.container {
  z-index: 2;
  min-height: 70px;
  padding: 24px;
}

.content {
  @include markdownRemoveMargins;

  font-weight: 600;
  text-align: center;
}
