@import 'breakpoints.scss';
@import 'colors.scss';

$cardWidth: 250px;

.container {
  display: flex;
  position: relative;
  flex: 0 0 $cardWidth;
  flex-direction: column;
  align-items: center;
  width: $cardWidth;
  height: 388px;
  border-radius: 5px;
  background: $white;
  box-shadow: 0 0 10px transparentize($black, 0.5);
  text-align: center;

  @include from(3) {
    min-height: 370px;
    margin: 0 10px 20px;
  }

  @include to(2) {
    margin-right: 25px;
  }

  > * {
    // NOTE: Fixes IE11 bug.
    // See https://github.com/philipwalton/flexbugs#flexbug-2

    max-width: 100%;
  }
}

$imageHeight: 160px;

.image {
  flex: 0 0 $imageHeight;
  width: $cardWidth;
  height: $imageHeight;
  margin-bottom: 33px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
  object-position: center top;
}

.number {
  position: absolute;
  top: $imageHeight;
  transform: translateY(-50%);
  border: 4px solid $white;
}

.title {
  margin: 0 0 5px;
  font-size: 17px;
  font-weight: 600;
}

@each $theme, $themeColors in $themes {
  .#{$theme} .title {
    color: map-get($themeColors, 'secondary');
  }
}

.description {
  width: 210px;
  margin: 0;
  color: $fontColor;
  white-space: normal;
}

.secondaryImage {
  width: calc(100% - 56px);
  margin: 13px 0 20px;
}

.action {
  width: 64.32%;
  margin: 20px 0;
}
