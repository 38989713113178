@import 'utils.scss';
@import 'colors.scss';

.container {
  margin-bottom: 50px;
}

.header {
  @include plainLink;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  margin-right: 20px;
  font-size: 24px;
}

.button {
  padding: 0.375em 0.8889em;
  border-radius: 5px;
  background: $grey600;
  color: $fontColorLight;
}

.tags {
  display: flex;
  color: $fontMedium;

  > * {
    margin: 0 8px;
  }

  > :first-child {
    margin-left: 0;
  }
}
