@import 'breakpoints.scss';
@import 'sizes.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex: 0 1 $maxWidth;
  flex-direction: column;
  justify-content: flex-start;
  color: $fontColorLight;
  text-shadow: 0 2px 4px transparentize($black, 0.9);

  @include to(2) {
    align-items: center;
    padding: 80px 20px 0;
  }

  @include from(3) {
    align-items: flex-start;
    padding: 150px 70px 50px;
  }

  &.noPadding {
    padding: 0;
  }
}

.rowContainer {
  position: relative;
  overflow: hidden;

  @include to(1) {
    min-height: 500px;
  }

  @include from(2) {
    min-height: 500px;
  }
}

.media {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.mediaContainer {
  @include from(3) {
    min-height: 700px;
  }
}

.content {
  display: flex;
  z-index: 1;
  flex-direction: column;
  max-width: 690px;

  @include at(1) {
    margin-left: 16px;
  }

  @include from(3) {
    align-items: flex-start;
  }

  @include to(3) {
    align-self: flex-start;
  }

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin-left: 0;
  }
}

.logo {
  align-self: flex-start;
  margin: 0;

  @include to(1) {
    height: 32px;
    margin-top: 180px;
  }

  @include from(2) {
    height: 40px;
  }
}

.emptyLogo {
  &.row {
    height: 0;
  }
}

.headline {
  font-weight: 600;

  @include to(1) {
    margin-top: 45px;
    font-size: 52px;
  }

  @include to(2) {
    line-height: 1em;
  }

  @include from(2) {
    font-size: 90px;
    line-height: 1.1em;
  }

  &.row {
    @include to(2) {
      margin-top: 0;
      font-size: 48px;
      line-height: 55px;
      text-align: center;
    }

    @include from(3) {
      font-size: 80px;
      line-height: 88px;
    }
  }
}

.subline {
  @include to(1) {
    font-size: 21px;
  }

  @include to(2) {
    margin-bottom: 60px;
  }

  @include from(2) {
    font-size: 18px;
  }

  @include from(3) {
    margin-bottom: 50px;
  }
}

.cta {
  display: flex;
  z-index: 1;
  flex-direction: column;

  @include to(3) {
    align-self: flex-start;
  }
}

.button {
  display: block;
  margin-bottom: 80px;
  text-align: center;
}

.image {
  @include at(1) {
    display: none;
  }
}

.mobileImage {
  @include from(2) {
    display: none;
  }
}
