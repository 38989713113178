@import 'breakpoints.scss';
@import 'utils.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
}

.content {
  max-width: 300px;
  margin-bottom: 25px;
  text-align: center;

  @include at(2) {
    font-size: 15px;
  }

  @include from(2) {
    font-size: 19px;
  }
}

.title {
  margin-bottom: 15px;
  font-weight: 600;
}

@each $theme, $themeColors in $themes {
  $secondary: map-get($themeColors, 'secondary');

  .#{$theme} {
    color: $secondary;
  }
}

.image {
  margin-bottom: 30px;

  @include to(1) {
    height: 56px;
  }

  @include from(2) {
    height: 103px;
  }
}

.button {
  width: 300px;
  margin-bottom: 15px;
}

.secondaryButton {
  width: 210px;
}
