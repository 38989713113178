@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  @include from(3) {
    margin-bottom: 74px;
    padding-top: 50px;
    background-color: $white;
  }

  @include to(2) {
    margin-bottom: 25px;
  }
}

.content.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include from(3) {
    padding: 34px 0 82px;
    border: 0;
  }

  @include to(2) {
    padding: 30px 78px;
  }
}

.title {
  @include from(3) {
    width: 100%;
    margin: 0;
    margin-bottom: 34px;
    text-align: center;
  }
}

.intro {
  @include from(3) {
    max-width: 375px;
    margin: 0 0 40px;
    font-size: 17px;
  }

  @include to(2) {
    margin: 0 0 25px;
  }
}

.logosVertical {
  height: 136px;
  margin-bottom: 44px;

  @include from(3) {
    display: none;
  }
}

.logosHorizontal {
  height: 41px;
  margin-bottom: 57px;

  @include to(2) {
    display: none;
  }
}

.instructions {
  @include from(3) {
    max-width: 375px;
    margin: 0 0 27px;
    font-size: 17px;
    line-height: 23px;
  }

  @include to(2) {
    margin: 0;
  }
}
